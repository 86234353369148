body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f5fa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gradient-ucla {
  background-color: #005687;
  /* background: linear-gradient(-180deg, #284476 0, #506489 100%) !important; */
}

.color-ucla {
  color: #005687;
}

.slick-prev:before,
.slick-next:before {
  color: #005687 !important;
}
.react-switch-bg {
  margin-right: 0.8rem !important;
}

.footer {
  padding: 20px !important;
  background: #005687 !important;
}
.card {
  border: 0 !important;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.5rem !important;
  margin: 0 1.2rem;
  height: 100%;
  }
  
  .w-card .card {
    width: 100%;
  }
  
  .card-body {
    flex: 0;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.5rem - 1px) !important;
  border-top-right-radius: calc(0.5rem - 1px) !important;
  max-height: 90px;
  object-fit: cover;
}
.rwt__tab {
  background: white !important;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.5rem !important;
  border: none !important;
  font-family: inherit;
  font-size: inherit;
  padding: 0 !important;
  margin-bottom: 10px;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.tab-image {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  width: 50px;
  height: 50px;
  object-fit: cover;
  left: 0;
  float: left;
}

.rwt__tab[aria-selected="true"] {
  position: relative;
  background: #005687 !important;
  color: white;
}

.rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  border-right: 0 !important;
}

.rwt__tablist[aria-orientation="vertical"] {
  border-right: 0 !important;
  width: 20%;
  /* position: sticky !important; */
  /* left: 0;  */
  /* required */
}
.rwt__tabpanel {
  background: white !important;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.5rem !important;
  padding: 2rem;
  webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  /* height: 100%; */
  background-color: #fff;
  width: 80%;
  text-align: center;
}
.container-stats {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  width: 800px;
  height: 800px;
  background-color: #fff;
}
.toggle-button-mae {
  border: 1px solid;
  border-color: #005687;
  border-radius: 8px;
  color: #005687;
  padding: 6px 12px;
  margin: 10px 2px;
  cursor: pointer;
  font-weight: 400;
}
.toggle-button-mae-selected {
  background: #005687;
  border-radius: 8px;
  color: white !important;
  padding: 6px 12px;
  margin: 10px 2px;
  cursor: pointer;
  font-weight: 600;
}
.toggle-mae-selected {
  background: #005687;
  color: white !important;
  cursor: pointer;
  font-weight: 600;
}
.toggle-button-mae-yellow {
  border: 1px solid;
  border-color: #feb91c;
  border-radius: 8px;
  color: #feb91c;
  padding: 2px 8px;
  margin: 10px 2px;
  cursor: pointer;
}
.toggle-button-mae-yellow-selected {
  background: #feb91c;
  border-radius: 8px;
  color: white;
  padding: 2px 8px;
  margin: 10px 2px;
  cursor: pointer;
}

.toggle-button-mae-vertical {
  border: 1px solid;
  border-color: #005687;
  border-radius: 8px;
  color: #005687;
  padding: 12px 8px;
  margin: 5px 2px;
  cursor: pointer;
  width: 90px;
  height: 90px;
  font-size: 13px;
  text-align: center;
}

.toggle-button-mae-selected-vertical {
  background: #005687;
  border-radius: 8px;
  color: white;
  padding: 12px 8px;
  margin: 5px 2px;
  cursor: pointer;
  width: 90px;
  height: 90px;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
}

.toggle-button-mae-yellow-vertical {
  border: 1px solid;
  border-color: #feb91c;
  border-radius: 8px;
  color: #feb91c;
  padding: 12px 8px;
  margin: 5px 2px;
  cursor: pointer;
  width: 90px;
  height: 90px;
  font-size: 13px;
  text-align: center;
}
.toggle-button-mae-yellow-selected-vertical {
  background: #feb91c;
  border-radius: 8px;
  color: white;
  padding: 12px 8px;
  margin: 5px 2px;
  cursor: pointer;
  width: 90px;
  height: 90px;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
}

.toggle-buttons {
  justify-content: center;
}
.footer-disclaimer-heading {
  font-size: 0.8rem;
  font-weight: bold;
}
.footer-disclaimer-text {
  font-size: 0.8rem;
}
.footer-container {
  padding: 0 100px;
}
.stats-table {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.5rem !important;
}
.stats-table > thead > tr > th {
  font-size: 0.85em;
  text-align: center;
  vertical-align: middle;
}

.stats-table > tbody > tr > td {
  font-size: 0.85em;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
}

.stats-table > tbody > tr > td:first-child {
  font-size: 0.85em;
  text-align: left;
  vertical-align: middle;
  padding: 8px;
}

.forecasts-table {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.5rem !important;
}
.forecasts-table > thead > tr > th {
  font-size: 0.85em;
  text-align: center;
  vertical-align: middle;
}

.forecasts-table > tbody > tr > td {
  font-size: 0.85em;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f5fa;
}

.mae-side-toggle {
  position: fixed;
  top: 30%;
  left: 0;
  transform: translateY(-50%);
  z-index: 999;
}
.mae-side-toggle-icon {
  margin-top: 10px;
  font-size: 1.8rem;
}

.no-data-card {
  min-height: 210px;
    /* transform: translate(0, 30%); */
}

.rwt__tab[aria-selected="true"] .tab-text {
  color: white;
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}

.green-text {
  color: #00c31e !important;
}

.red-text {
  color: #d52323 !important;
}

.mae-currency-name {
  font-size: 1.8rem;
  margin: 0 !important;
}

.maeCurrencyName {
  color: #5a5959;
}

.mae-interest-text {
  font-size: 1.8em;
  margin-top: 5px !important;
}

.mae-return-text {
  font-size: 1.2em;
}

.mae-signal-text h4 {
  font-size: 1.8em;
}

.mae-date-text {
  font-size: 1.1em;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container-img {
  position: relative;
  text-align: center;
  color: #5a5959;
}

.tab-text {
 vertical-align: middle;
  /* margin-top:.8rem; */
  clear: both;
  display: inline-block;
  font-size: 0.9em;
}

.rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  /* border-right: 4px solid #2c5e91 !important; */
    border-right: 4px solid transparent !important;
}

@media (max-width: 768px) {
  .mae-currency-name {
    font-size: 1.4rem;
  }
  .footer-disclaimer-heading {
    text-align: center;
  }
  /* .not-shown {
    display: none !important;
  } */
  .shown-ipdesk {
    display: block !important;
    margin-bottom: 2rem;
    padding: 0.8rem 1.2rem;
  }
  .recharts-default-legend {
    margin-top: 5px !important;
  }

  .toggle-button-mae-selected-vertical,
  .toggle-button-mae-yellow-vertical {
    border-radius: 5px;
    padding: 8px 4px;
    margin: 5px 2px;
    width: 65px;
    height: 65px;
    font-size: 12px;
    font-weight: 500;
  }
  .mae-side-toggle-text {
    font-size: 0.6rem;
    font-weight: 500;
    line-height: 1.4;
    display: block;
  }
  .mae-side-toggle-icon {
    font-size: 0.9rem;
  }
  .toggle-button-mae-vertical {
    background: #f2f5f9;
  }

  .toggle-button-mae-vertical,
  .toggle-button-mae-yellow-selected-vertical {
    width: 60px;
    height: 60px;
    padding: 8px 2px;
    margin: 5px 1.5px;
  }
  #flex-wrapper {
    display: flex;
    flex-direction: column;
  }
  #vertical-tab-one {
    padding: 1.2rem 0.5rem;
    width: 100%;
  }
  .rwt__tablist[aria-orientation="vertical"] {
    width: 100%;
    margin: auto !important;
    z-index: 0 !important;
    flex-direction: column;
    position: relative !important;
    flex-shrink: 0;
    flex-grow: 1;
  }

  .color-ucla {
    font-size: 1.3rem;
  }

  .tab-image {
    width: 40px;
    height: 40px;
  }
  #padding-container {
    padding: 50px 10px;
  }
  .p-3.row {
    padding: 0 !important;
  }
  .rwt__tabpanel {
    width: 100%;
  }
  .footer-container {
    padding: 0px 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1042px) {
  .footer-disclaimer-heading {
    text-align: center;
  }
  .mae-side-toggle-text {
    font-size: 1rem;
    font-weight: bold;
    display: block;
    line-height: 1.4em;
  }
  .mae-side-toggle-icon {
    font-size: 1.1rem;
  }

  .rwt__tablist[aria-orientation="vertical"] {
    /* width: 25%; */
  }
  .toggle-button-mae-vertical,
  .toggle-button-mae-yellow-selected-vertical {
    width: 70px;
    height: 70px;
    padding: 8px 0px;
    margin: 5px 1.5px;
  }
  .toggle-button-mae-selected-vertical,
  .toggle-button-mae-yellow-vertical {
    border-radius: 6px;
    padding: 1px 5px;
    margin: 5px 2px;
    width: 67px;
    height: 67px;
    font-size: 13px;
    font-weight: 500;
  }

  .mae-side-toggle-text {
    font-size: 0.8em;
    font-weight: 400;
  }
}

/*  */
@media (max-width: 1920px) {
  .rwt__tablist[aria-orientation="vertical"] {
    margin: auto;
    margin-top: 0px;
    margin-right: 1rem;
  }
  .slick-prev {
    left: 10;
  }
}

.signal-accuracy-text {
  text-align: left;
  margin-left: 6px;
  margin-top: -10px;
  margin-bottom: 0.5rem !important;
}

@media (min-width: 1024px) {
  .tab_list_css {
    margin-right: 8rem !important;
  }
}
@media (max-width: 990px) {
  #flex-wrapper {
    position: relative !important;
    flex-direction: column !important;
    width: 100% !important;
    margin: 0 !important;
    align-items: center !important;
  }

  #tablist {
    width: 80%;
    left:0 !important;
    position: relative !important;
  }
  #padding-container{
    padding:0 !important
  }
  .MuiList-root{
    width:100% !important
  }
}
@media(min-width:991px){
   .tab-text {
    /* margin-top: 1.2rem; */
  }
}